import React, { useEffect, useState } from "react";

import { UserClaims } from "@okta/okta-auth-js";

import { Autocomplete, TextField, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import mirLogo from "./../../assets/mir_logo.png";
import mirSentinelTheme from "../../styles/general";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

interface miRAppBarProps {
  user?: UserClaims;
  logout?: () => void;
  login?: () => void;
  imageSrc?: string;
  title?: string;
  region?: string;
  updateMirAppbar?: (upd: any) => void;
  showRegionSelector?: boolean;
}

const MirAppBar: React.FC<miRAppBarProps> = ({
  user,
  logout,
  login,
  imageSrc,
  title,
  region,
  updateMirAppbar,
  showRegionSelector,
}) => {
  const regions = ["us-east-1", "us-west-1"];
  // const regions = ["us-east-1", "us-west-1", "ap-southeast-1"];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputRegion, setInputRegion] = React.useState(regions[0]);
  const smallScreen = useMediaQuery(mirSentinelTheme.breakpoints.down("sm"));

  useEffect(() => {
    !!updateMirAppbar && updateMirAppbar({ region: regions[0] });
  }, []);

  useEffect(() => {
    if (!smallScreen) setAnchorEl(null);
  }, [smallScreen]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (smallScreen) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ paddingLeft: "50px", paddingRight: "50px" }}
    >
      <Toolbar disableGutters sx={{ height: "70px" }}>
        <img
          src={!!imageSrc ? imageSrc : mirLogo}
          style={{ height: "45px" }}
          className="App-logo"
          alt="logo"
        />
        <Typography
          variant="h4"
          noWrap
          component="a"
          sx={{
            marginLeft: "30px",
            display: { xs: "none", md: "flex" },
            fontWeight: 700,
            textDecoration: "none",
          }}
        >
          {!!title ? title : ""}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        {showRegionSelector && (
          <>
            {" "}
            <Typography
              noWrap
              sx={{
                display: { xs: "none", md: "flex" },
                color: "#0066ff",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              Selected region:{" "}
            </Typography>
            <Autocomplete
              value={region ? region : regions[0]}
              onChange={(event: any, newValue: string | null) => {
                if (!!newValue) !!updateMirAppbar && updateMirAppbar({ region: newValue });
              }}
              inputValue={inputRegion}
              onInputChange={(event, newInputValue) => {
                setInputRegion(newInputValue);
              }}
              options={regions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <LocationOnRoundedIcon
                        sx={{ color: mirSentinelTheme.palette.primary.dark }}
                      />
                    ),
                  }}
                  sx={{
                    "& fieldset": {
                      border: `3px solid ${mirSentinelTheme.palette.primary.main} !important`,
                      borderRadius: "6px",
                    },
                  }}
                  size="small"
                />
              )}
              sx={{
                minWidth: "200px",
                color: "#04142C",
                mr: "32px",
                ml: "8px",
              }}
              disableClearable
            />
          </>
        )}

        <Box sx={{ flexGrow: 0 }}>
          {!!user ? (
            <>
              <Tooltip title={user?.name || "User"}>
                <IconButton sx={{ p: 0 }} onClick={handleMenu}>
                  <Avatar
                    alt={user?.name}
                    sx={{ bgcolor: mirSentinelTheme.palette.primary.main }}
                  >
                    {user?.name?.match(/\b\w/g)?.join("")}
                  </Avatar>
                </IconButton>
              </Tooltip>
              {smallScreen ? (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                </Menu>
              ) : null}
            </>
          ) : null}
          {!!logout && !smallScreen ? (
            <>
              &nbsp; &nbsp; &nbsp;
              <Button variant="text" onClick={logout}>
                Logout
              </Button>
            </>
          ) : null}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MirAppBar;
