import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Dropzone,
  FileItem,
  FileValidated,
  UPLOADSTATUS,
} from "@dropzone-ui/react";

import { useOktaAuth } from "@okta/okta-react";

import {
  Box,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
} from "@mui/material";

import MirButton from "../mir-styles/src/components/MirButton/MirButton";
import {
  AlertMsgContext,
  AlertMsgType,
} from "../mir-styles/src/providers/AlertMsgProvider";

import "./MirReportGenPage.css";
import s3ObjectsService from "../services/S3Objects.service";
import JSZip from "jszip";
import ReporterListItem from "./components/ReporterListItem";

const region = "us-east-1";

const MirReportGenPage: React.FC = () => {
  // move to auth provider
  const { oktaAuth } = useOktaAuth();

  const { updateAlertMsg } = useContext(AlertMsgContext) as AlertMsgType;
  const [files, setFiles] = useState<FileValidated[]>([]);
  const [resultList, setResultList] = useState<
    | {
        key: string;
        fileSize: number;
        lastModifiedDate: Date;
        eTag: string;
      }[]
    | undefined
  >(undefined);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [refreshInterval, setRefreshInterval] = React.useState<
    NodeJS.Timer | undefined
  >(undefined);

  useEffect(() => {
    clearInterval(refreshInterval);
    const intervalFiles = setInterval(() => {
      refreshFiles(region);
    }, 5000);
    setRefreshInterval(intervalFiles);
    refreshFiles(region);
    return () => clearInterval(intervalFiles);
  }, []);

  const refreshFiles = (selectedRegion: string) => {
    s3ObjectsService
      .getResultsFromBucket(oktaAuth.getAccessToken() || "")
      .then((res) => {
        setResultList(res);
      });
  };

  const updateFiles = (incomingFiles: FileValidated[]) => {
    if (isUploading) {
      updateAlertMsg({
        severity: "error",
        message: `Can't add files while uploading.`,
        open: true,
      });
      setFiles(files);
      return;
    }
    let newFiles: FileValidated[] = [];
    incomingFiles.forEach((file) => {
      if (!file.valid) {
        updateAlertMsg({
          severity: "error",
          message:
            'The filetype for file "' +
            file.file.name +
            '" is not a valid filetype.',
          open: true,
        });
      } else {
        newFiles.push(file);
      }
    });
    setFiles(newFiles);
  };

  const onDelete = (id: string | number | undefined) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const downloadSeveralFiles = () => {
    setIsDownloading(true);

    let downloads: Promise<any>[] = [];
    let blobs: File[] = [];

    checked.forEach((key) => {
      const filename = key.split("/").at(-1);

      downloads.push(
        s3ObjectsService
          .getFileFromBucket(key, oktaAuth.getAccessToken() || "", region)
          .then((res) => {
            fetch(res)
              .then((response) => response.blob())
              .then((blob) => {
                blobs.push(
                  new File([blob], filename || "file", {
                    type: res.contentType,
                  })
                );
              })
              .catch(console.error);
          })
      );
    });

    Promise.all(downloads).then((values) => {
      const zip = new JSZip();
      const zipFilename = `Reports__MirSentinel__${new Date()
        .toJSON()
        .slice(0, -5)
        .replace("T", "_")}.zip`;

      setTimeout(() => {
        blobs.forEach((blob) => {
          zip.file(blob.name, blob);
        });
        zip.generateAsync({ type: "blob" }).then(function (content) {
          const url = window.URL.createObjectURL(
            new File([content], zipFilename || "file")
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${zipFilename}`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          if (!!link) link?.parentNode?.removeChild(link);
        });
        setIsDownloading(false);
        setChecked([]);
      }, 1000);
    });
  };

  const downloadSingleFile = (key: string) => {
    const filename = key.split("/").at(-1) || "";
    s3ObjectsService
      .getFileFromBucket(key, oktaAuth.getAccessToken() || "", region)
      .then((res) => {
        fetch(res)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            if (!!link) link?.parentNode?.removeChild(link);
          })
          .catch(console.error);
      });
  };

  const uploadFiles = () => {
    setIsUploading(true);

    let uploadingFiles: FileValidated[] = [];
    let uploads: Promise<any>[] = [];

    files.forEach((fileValidated) => {
      fileValidated.uploadStatus = UPLOADSTATUS.uploading;
      uploadingFiles.push(fileValidated);
      uploads.push(
        s3ObjectsService
          .putFileInBucket(
            fileValidated.file,
            oktaAuth.getAccessToken() || "",
            region
          )
          .then((res) => {
            const newFiles = files.map((file) => {
              if (file === fileValidated) {
                file.uploadStatus = UPLOADSTATUS.success;
              }
              return file;
            });
            setFiles(newFiles);
          })
      );
    });

    setFiles(uploadingFiles);

    Promise.all(uploads).then((values) => {
      setTimeout(() => {
        setIsUploading(false);
        updateAlertMsg({
          severity: "success",
          message: `Uploaded ${values.length} files successfully.`,
          open: true,
        });
        setFiles([]);
      }, 1000);
    });
  };

  const handleToggle = (value: string) => () => {
    if (isDownloading) {
      updateAlertMsg({
        severity: "error",
        message: `Can't add files while downloading.`,
        open: true,
      });
      setFiles(files);
      return;
    }
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "50px 50px 50px 50px",
        alignItems: "center",
        height: "calc(100vh - 70px)",
      }}
    >
      <Card
        sx={{
          height: "calc(100vh - 70px)",
          width: "70%",
          p: "40px 60px 40px 60px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "30px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        className="card"
        elevation={6}
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sm={4} className="grid__titles">
            <h2>File upload</h2>
          </Grid>
          <Grid item sm={8} className="grid__titles">
            <h2>Results download</h2>
          </Grid>
          <Grid
            item
            sm={4}
            sx={{
              display: "flex",
              height: "calc(100% - 147px)",
              justifyContent: "center",
            }}
          >
            <Dropzone
              onChange={updateFiles}
              value={files}
              accept=".csv"
              label="Drop your files here to upload or "
              maxHeight="90%"
              disableScroll
            >
              {files.map((file: any) => (
                <FileItem {...file} preview onDelete={onDelete} />
              ))}
            </Dropzone>
          </Grid>
          <Grid
            item
            sm={8}
            sx={{
              display: "flex",
              height: "calc(100% - 157px)",
              justifyContent: "center",
            }}
          >
            <List
              sx={{
                bgcolor: "background.paper",
                overflowY: "auto",
                overflowX: "hidden",
                pb: "30px",
                width: "100%",
                padding: "0px",
              }}
            >
              {!!resultList ? (
                resultList.length > 0 ? (
                  resultList.map((result) => {
                    const labelId = `checkbox-list-label-${result.key}`;

                    return (
                      <ReporterListItem
                        labelId={labelId}
                        result={result}
                        checked={checked}
                        downloadSingleFile={downloadSingleFile}
                        handleToggle={handleToggle}
                      />
                    );
                  })
                ) : (
                  <div className={"emptyMsgAndLoading"}>
                    <ListItem disablePadding>
                      <ListItemText
                        className="pre-wrap-list-item"
                        primary={"THERE ARE NO FILES HERE"}
                        sx={{ width: "100%", textAlign: "center" }}
                      />
                    </ListItem>
                  </div>
                )
              ) : (
                <div className={"emptyMsgAndLoading"}>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ paddingLeft: "calc(50% - 20px)" }}>
                      <CircularProgress />
                    </ListItemIcon>
                  </ListItem>
                </div>
              )}
            </List>
          </Grid>
          <Grid
            item
            sm={4}
            sx={{
              display: "flex",
              height: "90px",
              pt: "27px",
              justifyContent: "center",
            }}
          >
            <div className="buttons">
              <MirButton
                maxWidth
                onClick={uploadFiles}
                isDisabled={files.length === 0 || isUploading}
                isLoading={isUploading}
                isOutlined={true}
              >
                {isUploading ? (
                  <h3 className="buttons__text">UPLOADING</h3>
                ) : (
                  <h3 className="buttons__text">UPLOAD</h3>
                )}
              </MirButton>
            </div>
          </Grid>
          <Grid
            item
            sm={8}
            sx={{
              display: "flex",
              height: "90px",
              pt: "27px",

              justifyContent: "center",
            }}
          >
            <div className="buttons">
              <MirButton
                maxWidth
                onClick={downloadSeveralFiles}
                isDisabled={checked.length === 0 || isDownloading}
                isLoading={isDownloading}
              >
                {isDownloading ? (
                  <h3 className="buttons__text">DOWNLOADING</h3>
                ) : (
                  <h3 className="buttons__text">DOWNLOAD</h3>
                )}
              </MirButton>
            </div>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default MirReportGenPage;
