import * as React from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { useCookies } from 'react-cookie';

import config from './../config';


export interface IAuthState {
    tokens: any,
}

export type AuthType = {
    authContextState: IAuthState;
    updateAuthState: (authState: IAuthState) => void;
    setTokens: (cookies: any) => void;
    removeTokens: () => void;
    checkToken: () => boolean;
    getGroups: () => string[];
};

interface Props {
    children: React.ReactNode;
}

const oktaAuth = new OktaAuth(config.oidc);

export const AuthContext = React.createContext<AuthType | null>(null);

const AuthProvider: React.FC<Props> = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies(['mir_auth']);

    const setTokens = (tokens: any) => {
        updateAuthState({ ...authState, tokens })
        setCookie('mir_auth', tokens, { domain: process.env.REACT_APP_COOKIES_DOMAIN, path: '/' });
    }

    const removeTokens = () => {
        removeCookie('mir_auth', { domain: process.env.REACT_APP_COOKIES_DOMAIN })
    }

    const checkToken = (): boolean => {
        return (
            !!cookies.mir_auth
            && !!cookies.mir_auth.accessToken
            && !!cookies.mir_auth.idToken
            && !oktaAuth.tokenManager.hasExpired(cookies.mir_auth.accessToken)
            && !oktaAuth.tokenManager.hasExpired(cookies.mir_auth.idToken)
        )
    }

    const getGroups = (): string[] => {
        return cookies?.mir_auth?.accessToken?.claims?.groups || []
    }

    const [authState, setAuthState] = React.useState<IAuthState>({
        tokens: cookies.mir_auth,
    });

    const updateAuthState = (newAuthState: IAuthState) => {
        setAuthState({ ...authState, ...newAuthState });
    };

    return <AuthContext.Provider value={{ authContextState: authState, updateAuthState, setTokens, removeTokens, checkToken, getGroups }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;