import axios from "axios";


class S3ObjectsService {

    async getSignedS3Url(token: string, key: string, isInput: boolean = true, region: string = 'us-east-1', contentType: string = 'text/csv') {
        const data = {
            key,
            region,
            contentType,
            isInput
        }

        const baseUrl = `${process.env.REACT_APP_API_HOST}/getSignedUrl`;
        const res = await axios.post(
            baseUrl,
            data,
            {
                headers: {
                    token
                }
            });

        if (res.status === 200) return res.data.signedURL
    }

    async getResultsFromBucket(
        token: string
    ): Promise<any> {
        const baseUrl = `${process.env.REACT_APP_API_HOST}/getS3FileList`;
        const res = await axios.post(
            baseUrl,
            {},
            {
                headers: {
                    token
                }
            });
        if (res.status === 200) return res.data
    }

    async getFileFromBucket(
        key: string,
        token: string,
        region: string = 'us-east-1'
    ): Promise<any> {

        return await this.getSignedS3Url(token, key, false, region)

    }

    async putFileInBucket(
        file: File,
        // TODO improve token getter
        token: string,
        region: string = 'us-east-1'
    ): Promise<any> {

        const signedUrl = await this.getSignedS3Url(token, file.name, true, region)

        const result = await fetch(signedUrl, {
            method: 'PUT',
            body: file
        })

        return (result)
    }


}

const s3ObjectsService = new S3ObjectsService();
export default s3ObjectsService;
