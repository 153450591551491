import { FC } from "react";
import { Download } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface ListItemProps {
  labelId: string;
  result: any;
  checked: string[];
  downloadSingleFile: (key: string) => void;
  handleToggle: (value: string) => any;
}

const ReporterListItem: FC<ListItemProps> = ({
  labelId,
  result,
  checked,
  downloadSingleFile,
  handleToggle,
}) => {
  return (
    <ListItem
      key={result.key}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="comments"
          onClick={() => downloadSingleFile(result.key)}
        >
          <Download />
        </IconButton>
      }
      disablePadding
    >
      <ListItemButton role={undefined} onClick={handleToggle(result.key)}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.indexOf(result.key) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
            className="listItem__name"
          />
        </ListItemIcon>
        <ListItemText
          className="pre-wrap-list-item"
          id={labelId}
          primary={`${result.key}`}
          secondary={`Last modified: ${result.lastModifiedDate.toLocaleString()}    |    Size: ${
            result.fileSize
          } bytes`}
          primaryTypographyProps={{
            color: "#04142C",
          }}
          secondaryTypographyProps={{
            color: "#707070",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ReporterListItem;
