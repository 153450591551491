import React, { useEffect } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { Box } from '@mui/material';

const Logout = () => {
    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        async function logout() {
            // window.opener = null;
            // window.open('', '_self');
            // window.close();
            const tokens = await oktaAuth.tokenManager.getTokens()
            await oktaAuth.revokeAccessToken(tokens.accessToken)
            await oktaAuth.revokeRefreshToken(tokens.refreshToken)
            oktaAuth.tokenManager.clear();
            // await oktaAuth.signOut({ clearTokensBeforeRedirect: true, postLogoutRedirectUri: process.env.REACT_APP_OKTA_URL })
            const a = document.createElement('a');
            a.href = process.env.REACT_APP_OKTA_URL + ''
            a.click();
        }
        logout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 160px)'
        }}
        >
            <h1>You are being logged out from {process.env.REACT_APP_NAME} app</h1>
            <h3>You will be redirected to the Okta dashboard shortly</h3>
        </Box>
    );
};

export default Logout;
