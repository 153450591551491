import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { Box } from '@mui/material';

const LoginSSO = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function signIn() {
      if (!await oktaAuth.isAuthenticated()) {
        await oktaAuth.signInWithRedirect();
      } else {
        navigate('/')
      }
    }
    signIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
      <Box sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 160px)'
      }}
      >
        <h3>You logged in using Okta Dashboard</h3>
        <h1>You will be redirected to {process.env.REACT_APP_NAME} app shortly</h1>
      </Box>
  );
};

export default LoginSSO;
