import * as React from 'react';

export interface IAlertMsg {
    open: boolean,
    severity: 'error' | 'warning' | 'info' | 'success',
    message: string;
    title?: string;
}

export type AlertMsgType = {
    alertMsgState: IAlertMsg;
    updateAlertMsg: (alertMsg: IAlertMsg) => void;
    setAlertMsgOpen: (open: boolean) => void
};

interface Props {
    children: React.ReactNode;
}

export const AlertMsgContext = React.createContext<AlertMsgType | null>(null);

const AlertMsgProvider: React.FC<Props> = ({ children }) => {

    const setAlertMsgOpen = (open: boolean) => {
        setAlertMsgState({ ...alertMsgState, open})
    }

    const [alertMsgState, setAlertMsgState] = React.useState<IAlertMsg>({
        open: false,
        severity: 'info',
        message: ''
    });

    const updateAlertMsg = (newMirAppBarState: IAlertMsg) => {
        setAlertMsgState({...alertMsgState, ...newMirAppBarState});
    };

    return <AlertMsgContext.Provider value={{ alertMsgState, updateAlertMsg, setAlertMsgOpen }}>{children}</AlertMsgContext.Provider>;
};

export default AlertMsgProvider;