import React, { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { LoginCallback, useOktaAuth } from '@okta/okta-react';

import { RequiredAuth } from '../mir-styles/src/components/SecureRoute/SecureRoute';
import MirAppBar from '../mir-styles/src/components/AppBar/MirAppBar';
import { MirAppBarContext, miRAppBarType } from '../mir-styles/src/providers/MirAppBarProvider';
import MirReportGenPage from '../pages/MirReportGenPage';
import LoginSSO from '../mir-styles/src/components/LoginSSO/LoginSSO';
import Loading from '../mir-styles/src/components/Loading/Loading';
import ErrorPage, { ErrorRedirect } from '../mir-styles/src/components/ErrorPage/ErrorPage';
import Logout from '../mir-styles/src/components/Logout/Logout';


const AppRoutes = () => {
  const { mirAppBarState, updateMirAppbar } = useContext(MirAppBarContext) as miRAppBarType;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { oktaAuth, authState } = useOktaAuth();

  const navigate = useNavigate()


  useEffect(() => {
    oktaAuth.getUser().then((info) => {
      updateMirAppbar({
        logout: _handleLogout,
        user: info,
        title: 'Reporter'
      })
    }).catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated]);

  const _handleLogout = () => {
    navigate('/logout')
  }

  return (
    <>
      <MirAppBar title={mirAppBarState.title} logout={mirAppBarState.logout} user={mirAppBarState.user} />
      <Routes>
        <Route path='/login/sso' element={<LoginSSO />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/login/callback' element={<LoginCallback loadingElement={<Loading />} errorComponent={ErrorRedirect} />} />
        <Route path='/error' element={<ErrorPage title={process.env.REACT_APP_NAME || ''}/>} />
        <Route path='/' element={<RequiredAuth />}>
          <Route path='/' element={<MirReportGenPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;